:root {
  --default-text-color: #000;
  --orange-color: #e85b04;
  --darkgrey-color: #272121;
  --navbar--background-color-dark: var(--darkgrey-color);
  --navbar--background-color-light: #fff;
  --navbar-padding-horizontal: 15px;
  --navbar-padding-vertical: 30px;
  --navbar__inner--max-width: 1640px;
  --navbar__inner-height: 4rem;
  --navbar-height: 6rem;
  --navbar-item-padding-horizontal: 0.8rem;
  --navbar-item-padding-vertical: 20px;
  --navbar-item-color-hover: #f17310;
  --navbar-logo-width: 38px;
  --sidebar-height: 290px;
  --sidebar-item-padding-horizontal: 0;
  --sidebar-item-padding-vertical: 30px;
}

.navbar {
  background: #fff;
  padding: var(--navbar-padding-horizontal) var(--navbar-padding-vertical);
  height: var(--navbar-height);

  &--fixed-top {
    position: sticky;
    top: 0;
    z-index: 99999;
  }

  &__inner {
    height: var(--navbar__inner-height);
    max-width: var(--navbar__inner--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  // Left side
  &__logo {
    width: var(--navbar-logo-width);
  }

  &__items--right {
    & > .navbar__link {
      display: none;
    }
  }

  // Mobile menu
  &-sidebar {
    transform: translate3d(0, -100%, 0);
    transition-duration: 0.3s;
    transition-property: opacity, visibility, transform;
    opacity: 0;
    position: fixed;
    z-index: -1;
    transition-timing-function: ease-in-out;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    height: var(--sidebar-height);
    background: var(--navbar--background-color-dark);
    display: flex;
    align-items: flex-end;
  }

  &-sidebar__items {
    width: 100%;
    height: calc(100% - var(--navbar-height));
  }

  &-sidebar__item {
    height: 100%;
    padding: var(--sidebar-item-padding-horizontal)
      var(--sidebar-item-padding-vertical);
  }

  .menu__list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    max-width: var(--navbar__inner--max-width);
    margin: 0 auto;
    width: 100%;
  }

  .menu__link {
    color: #fff;
    text-decoration: none;
    display: block;
  }

  // When menu is open
  &-sidebar--show &-sidebar {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

// Laptop
@media only screen and (min-width: 769px) {
  :root {
    --navbar-logo-width: 48px;
  }

  .navbar {
    &__inner {
      border-bottom: none;
      padding-bottom: 0;
    }

    // Mobile menu
    &-sidebar {
      display: none;
    }

    // Laptop menu
    &__items--right {
      & > .navbar__link {
        display: inline-block;
        user-select: none;
        padding: var(--navbar-item-padding-horizontal)
          var(--navbar-item-padding-vertical);
        text-decoration: none;
        color: var(--default-text-color);

        &:hover {
          color: var(--navbar-item-color-hover);
        }
      }

      // Hide mobile menu icon
      & > .navbar__toggle {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 1025px) {
  :root {
    --navbar__inner-height: 4.5rem;
    --navbar-height: 6.5rem;
    --navbar-logo-width: 58px;
  }
}

// Helper classes
.clean-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
