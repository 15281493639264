:root {
  --default-font-size: 16px;
}

body {
  font-size: var(--default-font-size);
}

@media only screen and (min-width: 769px) {
  :root {
    --default-font-size: 18px;
  }
}

@media only screen and (min-width: 1025px) {
  :root {
    --default-font-size: 20px;
  }
}
