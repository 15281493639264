:root {
  --footer-title-fz: 22px;
}

.footer {
  background-color: var(--darkgrey-color);
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 70px 7vw;

  .logo__holder img {
    width: 70px;
    height: 75px;
  }

  .map__holder,
  .contact__holder,
  .time__holder {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .map__title,
  .contact__title,
  .time__title {
    font-size: var(--footer-title-fz);
    position: relative;
    width: fit-content;
    margin: 0 auto;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: -10%;
      width: 120%;
      height: 2px;
      background-color: var(--orange-color);
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    gap: 15px;

    a {
      color: currentColor;
    }

    &-street,
    &-phone,
    &-email {
      display: flex;
      flex-direction: row;
      align-items: center;

      & img {
        width: 40px;
        height: 25px;
      }
    }

    &-phone .phone-info ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .footer {
    display: grid;
    grid-template-areas:
      "map map map map"
      "logo time contact contact"
      "logo time contact contact"
      "small small small small";
    align-items: unset;
    justify-content: unset;

    .logo__holder {
      grid-area: logo;
      justify-content: center;
      display: flex;

      & img {
        width: 110px;
        height: 80px;
      }
    }

    .map__holder {
      grid-area: map;
    }

    .contact__holder {
      grid-area: contact;
      align-items: center;
    }
    .time__holder {
      grid-area: time;
      align-items: center;
    }
    .copyright {
      grid-area: small;
      padding-top: 20px;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .footer {
    display: grid;
    grid-template-areas:
      "logo contact time map map"
      "logo contact time map map"
      "small small small small small";
  }
}
